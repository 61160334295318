import React from "react"
import { Helmet } from "react-helmet"

// @ts-ignore
import favicon from "../../../assets/images/enact_icon.png"


interface SeoProps {
  seoData: SEOData,
  lang?: string

}
function Seo({ seoData, lang }:SeoProps):JSX.Element {

  return (
    <Helmet>
      {/* Universal Values */}
      <html lang={lang || "en"} />
      <title>{seoData.title}</title>
      <meta name="description" content={seoData.description} />
      {/* Open Graph */}
      <meta name="og:title" content={seoData.og_title || seoData.title} />
      <meta name="og:description" content={seoData.og_description || seoData.description} />
      {seoData.og_image ? <meta name="og:image" content={seoData.og_image} /> : null}
      {/* Twitter */}
      {seoData.tw_title ? <meta name="twitter:title" content={seoData.tw_title} /> : null}
      {seoData.tw_description ? <meta name="twitter:description" content={seoData.tw_description} /> : null}
      {seoData.tw_image ? <meta name="twitter:image" content={seoData.tw_image} /> : null}
      {seoData.tw_title ? <meta name="twitter:card" content="summary" /> : null}
      {/* Icon */}
      <link rel="icon" type="image/png" href={favicon} />
    </Helmet>
  )
}


function MapSEODefaults(story:any):SEOData {
  let seoData:SEOData = {
    title: story.name,
    description: ""
  }

  if(story && story.content && story.content.seo) {
    const seoProps = story.content.seo

    seoData.title       = seoProps.title || story.name || ""
    seoData.description = seoProps.description || ""

    seoData.og_title       = seoProps.og_title || seoData.title
    seoData.og_description = seoProps.og_description || seoData.description
    seoData.og_image       = seoProps.og_image || ""

    seoData.tw_title       = seoProps.twitter_title,
    seoData.tw_description = seoProps.twitter_description,
    seoData.tw_image       = seoProps.twitter_image
  }

  return seoData
}

export { MapSEODefaults }
export default Seo