import React from "react"
import { Richtext } from "storyblok-js-client"

import useStoryblok from "../lib/storyblok"

import GlobalLayout from "../components/layout/GlobalLayout"
import { RenderGlobalComponents } from "../globalComponents/GlobalDynamicComponent"
import { RenderComponents } from "../components/DynamicComponent"
import Seo, { MapSEODefaults } from "../components/internal/seo/Seo"

import FeedbackPanel from "../components/panels/feedbackPanel/FeedbackPanel"
import RelatedAssetsPanel from "../components/panels/relatedAssetsPanel/RelatedAssetsPanel"
import PrimaryAssetPanel from "../components/panels/primaryAssetPanel/PrimaryAssetPanel"
import NoIndexUnlessProduction from "../components/internal/noIndexUnlessProduction/NoIndexUnlessProduction"

function AssetPage({pageContext, location}:PageTemplateProps):JSX.Element {
  let story:any = pageContext ? pageContext.story : undefined
  let template:any = pageContext ? pageContext.template : undefined
  story = useStoryblok(story, location)

  let templateHeaderComponents:Array<JSX.Element> = []
  let templateFooterComponents:Array<JSX.Element> = []
  let pageContent:Array<JSX.Element> = []

  if(story && story.content && story.content.page_content) {
    pageContent = RenderComponents(story.content.page_content, story)
  }
  if(template && template.id) {
    let templateContent = JSON.parse(template.content)
    if(templateContent.header_items) {
      templateHeaderComponents = RenderGlobalComponents(templateContent.header_items, false, location?.pathname)
    }
    if(templateContent.footer_items) {
      templateFooterComponents = RenderGlobalComponents(templateContent.footer_items, false, location?.pathname)
    }
  } else if (template && template.header_items) {
    templateHeaderComponents = RenderGlobalComponents(template.header_items, false, location?.pathname)
    templateFooterComponents = RenderGlobalComponents(template.footer_items, false, location?.pathname)
  }

  let relatedText:Richtext = {
    content: [{
      type: "heading",
      attrs: {
        level: 3
      },
      content: [{
        text: "Related Videos",
        type: "text",
        marks: [{
          type: "styled",
          attrs: { class: "rich-text-align-center" }
        }]
      }]
    }]
  }

  // SEO
  let seoData = MapSEODefaults(story)
  if(story && story.content) {
    const seoProps = story.content.seo

    seoData.title       = seoProps.title || story.content.name || story.name || ""
    seoData.description = seoProps.description || ""

    seoData.og_title       = seoProps.og_title || seoData.title
    seoData.og_description = seoProps.og_description || seoData.description
    seoData.og_image       = seoProps.og_image || story.content.thumbnail ? story.content.thumbnail.image : ""
  }

  let type = "video"
  if(story && story.content && story.content.asset_type) {
    type = story.content.asset_type
  }

  return (
    <GlobalLayout>
      <NoIndexUnlessProduction />
      <Seo seoData={seoData} />
      {templateHeaderComponents}
      <div className={type === "exercise" ? "iqs-exercise-container" : "iqs-slim-container"}>
        <div className="container" style={{paddingBottom: 30}}>
          <h1>{story && story.content ? story.content.name : ""}</h1>
        </div>
        <PrimaryAssetPanel story={story} blok={{
          _uid: "page_primary_asset_panel",
          padding_top: "",
          padding_bottom: "small",
          background_color: "",
          anchor_id: "",
          bar_height_top: "",
          bar_height_bottom: "",
          bar_color_top: "",
          bar_color_bottom: ""
        }}/>
        {pageContent}
        <FeedbackPanel blok={{
          question_text: "Was this helpful?",
          feedback_text: "Send us your feedback",
          description_text: "",

          _uid: "page_feedback_panel",
          padding_top: "small",
          padding_bottom: "small",
          background_color: "light",
          anchor_id: "",
          bar_height_top: "",
          bar_height_bottom: "",
          bar_color_top: "",
          bar_color_bottom: ""
        }}
          story={story} />
        <RelatedAssetsPanel blok={{
          title: relatedText,
          related_assets: story.content.related_assets,

          _uid: "page_related_videos_panel",
          padding_top: "medium",
          padding_bottom: "medium",
          background_color: "",
          anchor_id: "",
          bar_height_top: "",
          bar_height_bottom: "",
          bar_color_top: "",
          bar_color_bottom: ""
        }} />
      </div>
      {templateFooterComponents}
    </GlobalLayout>
  )
}


export default AssetPage